import {
  CREATE_DEATAILS,
  CREATE_USER,
  LOGIN,
  USER_TRIP,
  GET_ALL_USER_DETAILS,
  UPDATE_USER_DETAILS,
  DELETE_USER_DETAILS,
  GET_TRIP_DETAILS,
  CREATE_TRIP_TRACKING,
  GET_VEHICLE_TYPE,
  GET_GARBAGE_TYPE,
  DELETE_USER_TRIP,
  TRIP_EDIT,
  GET_ALL_USER_TRIP_DETAILS,
  GET_TRIP_LICENSE_DETAILS,
  GET_USER_PROFILE,
  UPDATE_TRIP_STATUS,
  DRIVER_CARD_DETAILS,
  FQN_CARD_DETAILS,
  GET_VEHICLE_NUMBER,
  GET_USER_CAR_DETAILS,
  UPDATE_LANGUAGE,
  END_POINTS,
  LOGIN_EMPLOYEE_LIST,
  Location_List,
  RESUME_TRIP,
  GET_PAUSE_TRIP
} from '../constant/ApiConstants'
import {
  postRequest,
  getRequest,
  patchRequest,
  deleteRequest
} from './ApiHelper'
export const apiLogin = async (res: { emplyNo: string; hashedPassword: string }) => {
  const body = {
    username: res.emplyNo,
    password: res.hashedPassword
  }
  const response = await postRequest(body, LOGIN)
  return response
}
export const apiUserTripDetails = async (
  start_time: string | null,
  pageSize: number,
  pageNumber: number,
  userId: string,
  token: string
) => {
  const body = {
    start_time: start_time,
    pageSize: pageSize,
    pageNumber: pageNumber,
    user_id: userId
  }
  const response = await postRequest(body, USER_TRIP, token)
  return response
}
export const createUser = async (
  username: string,
  password: string,
  firstname: string,
  lastname: string,
  phonenumber: string,
  license_number: string
) => {
  const body = {
    username: username,
    password: password,
    firstname: firstname,
    lastname: lastname,
    phonenumber: phonenumber,
    license_number: license_number
  }
  const response = await postRequest(body, CREATE_USER)
  return response
}
export const createDetails = async (
  user_id: string,
  firstname: string,
  lastname: string,
  phonenumber: string,
  downloadURL: string,
  license_number: string,
  token: string
) => {
  const body = {
    user_id: user_id,
    firstname: firstname,
    lastname: lastname,
    phonenumber: phonenumber,
    profile_picture: downloadURL,
    license_number: license_number
  }
  const response = await postRequest(body, CREATE_DEATAILS, token)
  return response
}
export const updateDetails = async (
  user_id: number,
  firstname: string,
  lastname: string,
  phonenumber: string,
  downloadURL: string,
  license_number: string,
  token: string
) => {
  const body = {
    user_id: user_id,
    firstname: firstname,
    lastname: lastname,
    phonenumber: phonenumber,
    profile_picture: downloadURL,
    license_number: license_number
  }
  const response = await patchRequest(body, UPDATE_USER_DETAILS, token)
  return response
}
export const updateTripStatus = async (
  trip_id: number,
  status: string | number,
  startTime: any,
  token: string
) => {
  const body = {
    trip_id: trip_id,
    status: status,
    startTime: startTime
  }
  const response = await patchRequest(body, UPDATE_TRIP_STATUS, token)
  return response
}
export const updateUserLanguage = async (
  user_id: string,
  language: string,
  token: string
) => {
  const body = {
    user_id: user_id,
    language: language
  }
  const response = await patchRequest(body, UPDATE_LANGUAGE, token)
  return response
}
export const apiGetAllUserDetails = async (token?: string) => {
  const response = await getRequest(GET_ALL_USER_DETAILS, token)
  return response
}

export const getVehicleType = async (token?: string) => {
  const response = await getRequest(GET_VEHICLE_TYPE, token)
  return response
}
export const getGarbageType = async (token?: string) => {
  const response = await getRequest(GET_GARBAGE_TYPE, token)
  return response
}
export const getVehicleNumber = async (token?: string) => {
  const response = await getRequest(GET_VEHICLE_NUMBER, token)
  return response
}
export const apiDeleteUser = async (userId: number, token?: string) => {
  const body = {
    user_id: userId
  }
  const response = await deleteRequest(body, DELETE_USER_DETAILS, token)
  return response
}

export const getTripDetails = async (trip_id: string, token: string) => {
  const body = {
    trip_id: trip_id
  }
  const response = await postRequest(body, GET_TRIP_DETAILS, token)
  return response
}
export const getUserCarDetails = async (user_id: string, token: string) => {
  const body = {
    user_id: user_id
  }
  const response = await postRequest(body, GET_USER_CAR_DETAILS, token)
  return response
}
export const getTripLicenseDetails = async (user_id: string, token: string) => {
  const body = {
    user_id: user_id
  }
  const response = await postRequest(body, GET_TRIP_LICENSE_DETAILS, token)
  return response
}
export const getTripDriverCardDetails = async (
  user_id: string,
  token: string
) => {
  const body = {
    user_id: user_id
  }
  const response = await postRequest(body, DRIVER_CARD_DETAILS, token)
  return response
}
export const getTripFQNCardDetails = async (user_id: string, token: string) => {
  const body = {
    user_id: user_id
  }
  const response = await postRequest(body, FQN_CARD_DETAILS, token)
  return response
}
export const getAllUsersTripDetails = async (
  date: string,
  vehicleType: string,
  limit: number,
  offset: number,
  token: string
) => {
  const body = {
    start_time: date,
    cost_center_id: vehicleType,
    limit: limit,
    offset: offset
  }
  const response = await postRequest(body, GET_ALL_USER_TRIP_DETAILS, token)

  return response
}
export const createTrip = async (
  start_time: string,
  current_time: string,
  car_number: string,
  // total_time: string,
  // total_weight: string,
  car_id: string,
  cost_center_id: string,
  // pauseTime: string,
  // kmtotal: string,
  // total_current_day: string,
  location: string,
  postal_code: string,
  // litterfuel: string,
  garbageType: string,
  licenseExpiry: string,
  licenseImage: string,
  // driverCard: string,
  cardExpiryDate: string,
  // fqnCard: string,
  fqnExpirationDate: string,
  mileageData: string,
  signatureData: string,
  category: string,
  passenger_name: string,
  driver: string,
  userId: string,
  token: string,

) => {
  const body = {
    start_time: start_time,
    current_time: current_time,
    vehicle_number: car_number,
    // total_time: total_time,
    // total_weight: total_weight,
    car_id: car_number,
    cost_center_id: cost_center_id,
    // pauseTime: pauseTime,
    // kmtotal: kmtotal,
    // total_current_day: total_current_day,
    location: location,
    postal_code: postal_code,
    // litterfuel: litterfuel,
    garbage_type_id: garbageType,
    license_expiration_date: licenseExpiry,
    license_picture: licenseImage,
    // driver_card: driverCard,
    card_expiration_date: cardExpiryDate,
    // fqn_card: fqnCard,
    fqn_expiration_card: fqnExpirationDate,
    start_mileage: mileageData,
    signature: signatureData,
    category: category,
    passenger_name: passenger_name,
    driver: driver,
    user_id: userId,

  }
  const response = await postRequest(body, CREATE_TRIP_TRACKING, token)
  return response
}
export const apiDeleteUserTrip = async (trip_id: number, token?: string) => {
  const body = {
    trip_id: trip_id
  }
  const response = await deleteRequest(body, DELETE_USER_TRIP, token)
  return response
}
export const EditedTrip = async (
  startTime: string,
  endTime: string,
  vehicleType: string,
  carId: number,
  totalTime: string | number,
  userId: string | number,
  tripId: string | number,
  token: string,
  location: string,
  postal_code: string,
  start_mileage: any
) => {
  const body = {
    start_time: startTime,
    end_time: endTime,
    cost_center_id: vehicleType,
    car_id: carId,
    total_time: totalTime,
    user_id: userId,
    trip_id: tripId,
    token: token,
    location: location,
    postal_code: postal_code,
    start_mileage: start_mileage
  }
  const response = await patchRequest(body, TRIP_EDIT, token)
  return response
}

export const endPoints = async (
  trip_id: number,
  damaged: string,
  dumped_times: string,
  final_mileage: string,
  status: string,
  diesel_filled_up: string,
  damaged_picture: string,
  passenger_name: string,
  litterfuel: any,
  reFuel: any,
  pauseTime: any,
  currentDateTime: string,
  token?: string

) => {
  const body = {

    trip_id: trip_id,
    damaged: damaged,
    dumped_times: dumped_times,
    final_mileage: final_mileage,
    status: status,
    diesel_filled_up: diesel_filled_up,
    damaged_picture: damaged_picture,
    passenger_name: passenger_name,
    litterfuel: litterfuel,
    reFuel: reFuel,
    pauseTime: pauseTime,
    currentDateTime: currentDateTime,
  }
  const response = await postRequest(body, END_POINTS, token)
  return response
}
export const getUserProfile = async (user_id: string, token: string) => {
  const body = {
    user_id: user_id
  }
  const response = await postRequest(body, GET_USER_PROFILE, token)
  return response
}

export const apiLoginEmployeeList = async () => {

  const response = await getRequest(LOGIN_EMPLOYEE_LIST,)
  return response
}

export const apiGetLocationList = async (token: string) => {
  const response = await getRequest(Location_List, token)
  return response
}
export const resumeTripStatus = async (
  start_time: any,
  end_time: string,
  trip_id: number,
  status: string | number,
  token: string,
) => {
  const body = {
    start_time: start_time,
    end_time: end_time,
    trip_id: trip_id,
    status: status,
    token: token,
  }
  const response = await patchRequest(body, RESUME_TRIP, token)
  return response
}
export const getPasuseResumeTrip = async (trip_id: any, token: string) => {
  const body = {
    trip_id: trip_id
  }
  const response = await postRequest(body, GET_PAUSE_TRIP, token)
  return response
}